<template>
  <div class="">
    <div class="box">
      <div class="columns">
        <div class="column">
          <div class="field">
            <label class="label">Full Name<sup>*</sup></label>
            <div class="control">
              <input
                class="input"
                type="text"
                id="profile-fullname"
                placeholder="Name (Required)"
                v-model="fullName"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Designation</label>
            <div class="control">
              <input
                class="input"
                type="text"
                id="profile-salutation"
                placeholder="Designation"
                v-model="designation"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <input
                class="input"
                type="tel"
                id="profile-phone"
                placeholder="Phone"
                v-model="phone"
              />
            </div>
          </div>
        </div>
        <div class="column">
          <div class="field">
            <label class="label">Address</label>
            <div class="control">
              <textarea
                class="input"
                type="text"
                id="profile-phone"
                placeholder="Address"
                v-model="address"
                style="height: 196px"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="columns is-desktop">
        <div class="column">
          <div class="field">
            <label class="label">Signature<sup>*</sup></label>
            <div class="box">
              <VueSignaturePad
                width="100%"
                height="227px"
                ref="signaturePad"
                :options="{
                  onBegin: () => {
                    $refs.signaturePad.resizeCanvas();
                  },
                }"
              />

              <div class="field has-text-right">
                <nav class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <input
                        class="checkbox is-medium"
                        id="exampleCheckboxNormal"
                        type="checkbox"
                        name="exampleCheckboxNormal"
                        v-model="checkSubmit"
                      />
                      &nbsp;
                      <label
                        style="margin-left: 7px"
                        for="exampleCheckboxNormal"
                        >&nbsp;&nbsp;Accept Terms &amp; Conditions
                      </label>
                    </div>
                  </div>

                  <div class="level-right">
                    <p class="level-item">
                      <button class="button" @click="clear">Clear</button>
                    </p>
                    <p class="level-item">
                      <button class="button" @click="undo">Undo</button>
                    </p>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <nav class="level">
            <div class="level-left">
              <div class="level-item">
                <p class="subtitle is-5">
                  <!-- <b-button
                    outlined
                    type="is-danger"
                    icon-pack="fas"
                    icon-left="backward"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action"
                  >
                    Previous
                  </b-button> -->
                </p>
              </div>
              <div class="level-item">
                <!-- <b-button
                  outlined
                  type="is-success"
                  icon-pack="fas"
                  icon-right="forward"
                  :disabled="next.disabled"
                  @click.prevent="next.action"
                >
                  Next
                </b-button> -->
              </div>
            </div>

            <div class="level-right">
              <p class="level-item">
                <button
                  id="submit-profile"
                  @click="setProfile_WetSign"
                  class="button is-primary is-outlined is-link"
                  :disabled="
                    !checkSubmit ||
                    !$refs.signaturePad ||
                    $refs.signaturePad.isEmpty() ||
                    fullName === ''
                  "
                >
                  Submit
                </button>
              </p>
            </div>
          </nav>
        </div>
      </div>
      <!-- <div class="steps" id="stepsDemo">
        <div class="step-item is-active is-success">
          <div class="step-marker">1</div>
          <div class="step-details">
            <p class="step-title">Account</p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-marker">2</div>
          <div class="step-details">
            <p class="step-title">Profile</p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-marker">3</div>
          <div class="step-details">
            <p class="step-title">Social</p>
          </div>
        </div>
        <div class="step-item">
          <div class="step-marker">4</div>
          <div class="step-details">
            <p class="step-title">Finish</p>
          </div>
        </div>
        <div class="steps-content">
          <div class="step-content has-text-centered is-active">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Username</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      name="username"
                      id="username"
                      type="text"
                      placeholder="Username"
                      autofocus
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Password</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icon has-icon-right">
                    <input
                      class="input"
                      type="password"
                      name="password"
                      id="password"
                      placeholder="Password"
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Confirm password</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icon has-icon-right">
                    <input
                      class="input"
                      type="password"
                      name="password_confirm"
                      id="password_confirm"
                      placeholder="Confirm password"
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="step-content has-text-centered">
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Firstname</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      name="firstname"
                      id="firstname"
                      type="text"
                      placeholder="Firstname"
                      autofocus
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Last name</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icon has-icon-right">
                    <input
                      class="input"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Last name"
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Email</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control has-icon has-icon-right">
                    <input
                      class="input"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="Email"
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="step-content has-text-centered">
            <div class="field is-horizontal"> 
              <div class="field-label is-normal">
                <label class="label">Facebook account</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      name="facebook"
                      id="facebook"
                      type="text"
                      placeholder="Facebook account url"
                      autofocus
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="field is-horizontal">
              <div class="field-label is-normal">
                <label class="label">Twitter account</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <div class="control">
                    <input
                      class="input"
                      name="twitter"
                      id="twitter"
                      type="text"
                      placeholder="Twitter account url"
                      autofocus
                      data-validate="require"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="step-content has-text-centered">
            <h1 class="title is-4">Your account is now created!</h1>
          </div>
        </div>
        <div class="steps-actions">
          <div class="steps-action">
            <a href="#" data-nav="previous" class="button is-light">Previous</a>
          </div>
          <div class="steps-action">
            <a href="#" data-nav="next" class="button is-light">Next</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { endpoint } from "../services/global-constant";
import Vue from "vue";
import VueSignaturePad from "vue-signature-pad";
Vue.use(VueSignaturePad);

export default {
  data() {
    return {
      fullName: "",
      phone: "",
      address: "",
      designation: "",
      checkSubmit: false,
      registered: null,
      customNavigation: true,
      api: null,
    };
  },
  components: {},
  props: {
      userData: {
        type: Object,
      },
      signatureData: {
        type: Object,
      },
      toUpdate: {
        type: Boolean,
        default: false
      },
  },
  mounted() {
    this.init();
  },
  computed: {},
  watch: {},
  methods: {
    async init() {
      console.log("user data",this.signatureData, this.toUpdate, this.userData)
      if(this.toUpdate){
        this.fullName = this.userData.data.full_name
this.phone = this.userData.data.phone
this.address = this.userData.data.address
this.designation = this.userData.data.designation
        this.$refs.signaturePad.fromDataURL(this.signatureData.data.signature_data)
      }
    },
    setProfile_WetSign: async function () {
      const { data } = this.$refs.signaturePad.saveSignature();
      var setProfileData = {
        full_name: this.fullName,
        phone: this.phone,
        address: this.address,
        designation: this.designation,
      };

      var setWetSignData = {
        consent: true,
        signature_data: data,
      };

      var setProfile = await this.$store.getters.authFlowObject.postReq(
        endpoint.USER_PROFILE,
        setProfileData,
        null
      );
      console.log("Console log : setProfile", setProfile);

      var setWetSign = await this.$store.getters.authFlowObject.postReq(
        endpoint.USER_WET_SIGN,
        setWetSignData,
        null
      );
      console.log("Console log : init -> setWetSign", setWetSign);

      if (
        setWetSign.status === 200 &&
        (setProfile.status === 200 || setProfile.status === 201)
      ) {
        var userDetails;
        userDetails = await this.$store.getters.authFlowObject.getReq(
          endpoint.USER_DETAILS,
          null
        );

        console.log("Console log : userDetails", userDetails);

        await this.$store.dispatch("USER_DETAILS", userDetails.data);

        // this.$router.push("/home");
        // reload app instead of switching to home so that websocket is properly connected
        // TBD proper fix for websocket connection on user registration
        if(!this.toUpdate){
          window.location.reload();
        }else{
          this.$emit("close-update-profile",true)
        }
        
      }
    },
    dothis: async function () {
      console.log("Can here 1");
      this.$nextTick(() => {
        console.log("Can here");

        this.$refs.signaturePad.resizeCanvas();
      });
    },
    undo: async function () {
      this.$refs.signaturePad.undoSignature();
    },
    clear: async function () {
      this.$refs.signaturePad.clearSignature();
    },
    save: async function () {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      console.log(isEmpty);
      console.log(data);
    },
  },
};
</script>

<style lang="scss">
.step-marker .icon {
  display: flex !important;
  justify-items: center;
}
</style>
